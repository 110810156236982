import { firebaseApp } from './Firebase'
import 'firebase/analytics'

export function logDownloadAppAlert() {
  firebaseApp.analytics().logEvent('web_app_download_alert', {})
}

export function logVoteClick() {
  firebaseApp.analytics().logEvent('web_vote', {})
}

export function logProfileClick() {
  firebaseApp.analytics().logEvent('web_profile', {})
}

export function logTagClick() {
  firebaseApp.analytics().logEvent('web_tag', {})
}

export function logCommentClick() {
  firebaseApp.analytics().logEvent('web_comment', {})
}

export function logDetailClick() {
  firebaseApp.analytics().logEvent('web_detail', {})
}

export function logGetAppHeaderClick() {
  firebaseApp.analytics().logEvent('web_get_app_header', {})
}

export function logGetAppAlertClick() {
  firebaseApp.analytics().logEvent('web_get_app', {})
}

export function logDownloadBadgeClick() {
  firebaseApp.analytics().logEvent('web_download_badge', {})
}

export function logStartShare() {
  firebaseApp.analytics().logEvent('web_start_share', {})
}

export function logShare() {
  firebaseApp.analytics().logEvent('web_share', {})
}

export function mobileDynamicItemOpen() {
  firebaseApp.analytics().logEvent('mobile_dynamic_item_open', {})
}

export function mobileDynamicOpen() {
  firebaseApp.analytics().logEvent('mobile_dynamic_open', {})
}
