import firebase from 'firebase/app'
import 'firebase/analytics'

// This is usually the apiKey of firebaseConfig,
// but we currently had a verify-problem on megalol-staging firebase
// to generate dynamic short links. (we have a one-month-timeout on app.staging.mega.lol)
// So we use the live-megalol-project to generate them for now
// I've talked with Martin about this.
export const dynamicLinkAPIKey = 'AIzaSyALu2UJ-1V2shaUqksyYNxXt6HW0wpX4ME'

// staging
// const firebaseConfig = {
//   apiKey: 'AIzaSyApT-PKwvHw0gopWAv5CFeK924Y_nKiMO0',
//   authDomain: 'quotes-staging-f1b4c.firebaseapp.com',
//   databaseURL: 'https://quotes-staging-f1b4c.firebaseio.com',
//   projectId: 'quotes-staging-f1b4c',
//   storageBucket: 'quotes-staging-f1b4c.appspot.com',
//   messagingSenderId: '920412280138',
//   appId: '1:920412280138:web:94c892ac5438c6cdbd1db3',
//   measurementId: 'G-3X3DJP4LZP'
// }

const firebaseConfig = {
  apiKey: 'AIzaSyALu2UJ-1V2shaUqksyYNxXt6HW0wpX4ME',
  authDomain: 'quotes-953d9.firebaseapp.com',
  databaseURL: 'https://quotes-953d9.firebaseio.com',
  projectId: 'quotes-953d9',
  storageBucket: 'quotes-953d9.appspot.com',
  messagingSenderId: '315460020616',
  appId: '1:315460020616:web:41f8d02443a43c732eb23d',
  measurementId: 'G-R4S7CKQZVW'
}

export const firebaseApp = firebase.initializeApp(firebaseConfig)

export function enableAnalytics() {
  firebaseApp.analytics().setAnalyticsCollectionEnabled(true)
}

export function disableAnalytics() {
  firebaseApp.analytics().setAnalyticsCollectionEnabled(false)
}
