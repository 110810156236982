import { ConfigValue, remoteConfig } from '@/services/RemoteConfig'
import axios from 'axios'
import { dynamicLinkAPIKey } from '@/services/Firebase'
// export const defaultDynamicLink = 'https://app.mega.lol/getApp'

// Google Campaign source
const utmSource = 'webversion'
let utmMedium = ''

// sets the utmMedium src to be used when creating links with campaign parameters
export function setUtmMedium(toMedium: string) {
  utmMedium = toMedium
}

// creates a shortened dynamic link from return value of function createDynamicLink()
export function createDynamicShareLink(forItemId: string): Promise<string> {
  return new Promise(function (resolve) {
    if (!forItemId) {
      resolve(remoteConfig.getValue(ConfigValue.deepLinkDomain).asString())
      return
    }

    const longDynLink = createDynamicLink(forItemId)
    const reqHeader = {
      'Content-Type': 'application/json'
    }

    const reqBody = {
      longDynamicLink: longDynLink,
      suffix: {
        option: 'SHORT'
      }
    }

    axios.post('https://firebasedynamiclinks.googleapis.com/v1/shortLinks?key=' + dynamicLinkAPIKey, reqBody, { headers: reqHeader })
      .then(response => {
        if (response.data.shortLink) {
          resolve(response.data.shortLink)
          return
        }

        resolve(longDynLink)
      }).catch(err => {
        console.error(err)
        resolve(longDynLink)
      })
  })
}

export function createAppstoreLink(): string {
  const iosID = remoteConfig.getValue(ConfigValue.shareIOSId).asString()

  // id1059663972?pt=117602806&ct=Webversion&mt=8
  return (
    'https://apps.apple.com/app/apple-store/' +
    iosID +
    '?ct=' + utmSource + // apple campaign name
    '&at=' + utmMedium + // apple affiliate token
    '&platform=iphone' // show iPhone screenshots
  )
}

export function createGoogleStoreLink(): string {
  const androidPackage = remoteConfig.getValue(ConfigValue.shareAndroidPackage).asString()

  return (
    'https://play.google.com/store/apps/details' +
    '?id=' + androidPackage +
    '&utm_source=' + utmSource + // google campaign name
    '&utm_medium=' + utmMedium // google medium
  )
}

// A link that opens the app when installed + redirects to the appstore if uninstalled / on desktop
export function createDynamicLink(forItemId: string): string {
  if (!forItemId) {
    return remoteConfig.getValue(ConfigValue.defaultDynamicLink).asString()
  }

  const domain = remoteConfig.getValue(ConfigValue.deepLinkDomain).asString()

  let link = remoteConfig.getValue(ConfigValue.deepLinkItem).asString().replace('{itemId}', forItemId)
  if (!link.startsWith('http')) {
    // Links without ssl will result in invalid firebase error
    link = 'https://' + link
  }

  const iosPackage = remoteConfig.getValue(ConfigValue.shareIOSPackage).asString()
  const iosID = remoteConfig.getValue(ConfigValue.shareIOSId).asString()
  const androidPackage = remoteConfig.getValue(ConfigValue.shareAndroidPackage).asString()
  const desktopTarget = link // 'https://play.google.com/store/apps/details?id=' + androidPackage

  // skips app-preview-page
  const efr = '1'

  // https://app.mega.lol/?link=https://mega.lol/detail/140030&apn=com.megalol.app&ibi=com.megalol.app&isi=1273813626&efr=1&ofl=https://play.google.com/store/apps/details?id%3Dcom.megalol.app&_imcp=1
  // https://app.mega.lol/?link=https://mega.lol/detail/226916&apn=com.megalol.app&ibi=com.megalol.app&isi=1273813626&efr=1&ofl=https://www.google.at
  return (
    domain +
    '?link=' + link +
    '&apn=' + androidPackage +
    '&ibi=' + iosPackage +
    '&isi=' + iosID +
    '&efr=' + efr +
    '&ofl=' + desktopTarget +
    '&ct=' + utmSource + // apple campaign name
    '&at=' + utmMedium + // apple affiliate token
    '&utm_source=' + utmSource + // google campaign name
    '&utm_medium=' + utmMedium // google medium
  )
}

// A link that only opens the app when installed and nothing else
export function createMobileOnlyDynamicLink(forItemId: string): string {
  if (!forItemId) {
    return remoteConfig.getValue(ConfigValue.defaultDynamicLink).asString()
  }

  const domain = remoteConfig.getValue(ConfigValue.deepLinkDomain).asString()

  let link = remoteConfig.getValue(ConfigValue.deepLinkItem).asString().replace('{itemId}', forItemId)
  if (!link.startsWith('http')) {
    // Links without ssl will result in invalid firebase error
    link = 'https://' + link
  }

  const iosPackage = remoteConfig.getValue(ConfigValue.shareIOSPackage).asString()
  const androidPackage = remoteConfig.getValue(ConfigValue.shareAndroidPackage).asString()

  // https://app.mega.lol/?link=https://mega.lol/detail/140030&apn=com.megalol.app&ibi=com.megalol.app
  // https://app.mega.lol/?link=https://mega.lol/detail/226916&apn=com.megalol.app&ibi=com.megalol.app
  return (
    domain +
    '?link=' + link +
    '&apn=' + androidPackage +
    '&ibi=' + iosPackage +
    '&ct=' + utmSource + // apple campaign name
    '&at=' + utmMedium + // apple affiliate token
    '&utm_source=' + utmSource + // google campaign name
    '&utm_medium=' + utmMedium + // google medium
    '&afl=' + '&ifl=' + '&ofl=' +
    '&efr=1')
}
