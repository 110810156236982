import { firebaseApp } from './Firebase'
import 'firebase/auth'

export let firebaseUserID: string = ''

firebaseApp.auth().onAuthStateChanged((user) => {
  if (user) {
    console.log('User signed in: ' + user.uid)
    firebaseUserID = user.uid
  } else {
    console.log('User signed out')
    firebaseUserID = ''
    firebaseApp.auth().signInAnonymously()
  }
})

firebaseApp.auth().signInAnonymously()
