<template>
  <div v-if="!isHidden" id="alertBackground">
    <footer id="alertWrapper">
      <div class="container-fluid">
        <div class="row justify-content-center">
          <div
            class="col-12 col-lg-6"
            style="padding: 0"
          >
            <div id="alert">
              <svg id="closeButton" @click="closeAlert">
                <use href="@/assets/icons/close.svg#shape"></use>
              </svg>

              <p id="alertTitle">{{ t("gdpr-header") }}</p>
              <p id="alertText">{{ t("gdpr-text") }}</p>

              <div id="alertButtonWrapper">
                <button
                  class="btn btn-secondary alertButton"
                  v-on:click="moreInfo"
                >
                  {{ t("gdpr-info") }}
                </button>

                <a class="btn btn-success alertButton" @click="closeAlert"
                  >{{ t("gdpr-accept") }}
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </footer>
  </div>
</template>

<script scoped lang="ts">
import * as router from '@/router'
import { defineComponent, ref } from 'vue'
import { useI18n } from 'vue-i18n'
import { enableAnalytics } from '@/services/Firebase'

export default defineComponent({
  setup() {
    const isHidden = ref(localStorage.getItem('cookies') === 'consent')
    localStorage.setItem('cookies', 'consent')
    const { t } = useI18n({})
    return { t, isHidden }
  },

  methods: {
    closeAlert() {
      localStorage.setItem('cookies', 'consent')
      enableAnalytics()
      this.isHidden = true
    },

    moreInfo() {
      localStorage.setItem('cookies', 'declined')
      this.isHidden = true
      router.default.push({ name: router.routeNames.COOKIES })
    }
  }
})
</script>

<style lang="sass" scoped>
#alertBackground
  pointer-events: none
  position: absolute
  top: 0
  bottom: 0
  left: 0
  right: 0

#alertWrapper
  position: fixed
  left: 0
  bottom: 0
  width: 100%

#alert
  position: relative
  pointer-events: all
  border-radius: 25px 25px 0px 0px
  padding: 25px
  width: 100%
  background-color: $color_surface_overlay_transparent

#closeButton
  cursor: pointer
  height: 30px
  width: 30px
  position: absolute
  right: 12px
  top: 12px
  fill: $color_on_background

#alertTitle
  font-size: 1em
  text-align: left
  font-weight: 800
  margin: 0

#alertText
  font-size: 1em
  text-align: left

#alertButtonWrapper
  display: table
  margin-left: auto

.alertButton
  margin-left: 6px
  margin-right: 6px

#alertImage
  width: 80%
  border-radius: 12px
  margin-left: auto
  margin-right: auto
  display: block
</style>

<i18n>
{
  "de": {
    "gdpr-header": "Hinweis",
    "gdpr-text": "Wir und ausgewählte Dritte setzen Cookies und ähnliche Technologien ein, so wie in der Cookie-Richtlinie beschrieben. Sie willigen in den Einsatz solcher Technologien ein, indem Sie diesen Hinweis schließen.",
    "gdpr-accept": "Zustimmen",
    "gdpr-info": "Mehr erfahren und anpassen"
  },

  "en": {
    "gdpr-header": "Notice",
    "gdpr-text": "We and selected third parties use cookies or similar technologies as specified in the cookie policy. You can consent to the use of such technologies by closing this notice.",
    "gdpr-accept": "Accept",
    "gdpr-info": "Learn more and customize"
  }
}
</i18n>
