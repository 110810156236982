import { createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"
import _imports_0 from '@/assets/images/Logo.svg'


const _hoisted_1 = { class: "header container-fluid" }
const _hoisted_2 = {
  class: "row",
  style: {"height":"100%"}
}
const _hoisted_3 = { class: "logo-container col-12" }
const _hoisted_4 = { id: "footerLinkContainer" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_router_view = _resolveComponent("router-view")!
  const _component_cookies_alert = _resolveComponent("cookies-alert")!
  const _component_download_app_alert = _resolveComponent("download-app-alert")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createElementVNode("div", _hoisted_1, [
      _createElementVNode("div", _hoisted_2, [
        _createElementVNode("div", _hoisted_3, [
          _createElementVNode("img", {
            src: _imports_0,
            id: "header-logo-simple",
            onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.goHome && _ctx.goHome(...args)))
          })
        ])
      ])
    ]),
    _createVNode(_component_router_view),
    _createElementVNode("footer", null, [
      _createElementVNode("div", _hoisted_4, [
        _createElementVNode("a", {
          class: "footerLink",
          onClick: _cache[1] || (_cache[1] = 
//@ts-ignore
(...args) => (_ctx.goAGB && _ctx.goAGB(...args)))
        }, _toDisplayString(_ctx.t('agb')), 1),
        _createElementVNode("a", {
          class: "footerLink",
          onClick: _cache[2] || (_cache[2] = 
//@ts-ignore
(...args) => (_ctx.goPrivacy && _ctx.goPrivacy(...args)))
        }, _toDisplayString(_ctx.t('privacy')), 1),
        _createElementVNode("a", {
          class: "footerLink",
          onClick: _cache[3] || (_cache[3] = 
//@ts-ignore
(...args) => (_ctx.goImprint && _ctx.goImprint(...args)))
        }, _toDisplayString(_ctx.t('imprint')), 1),
        _createElementVNode("a", {
          class: "footerLink",
          onClick: _cache[4] || (_cache[4] = 
//@ts-ignore
(...args) => (_ctx.goCookies && _ctx.goCookies(...args)))
        }, _toDisplayString(_ctx.t('cookies')), 1)
      ])
    ]),
    _createVNode(_component_cookies_alert),
    _createVNode(_component_download_app_alert, {
      isHidden: !_ctx.downloadAlertShown,
      itemID: _ctx.itemID,
      "onDownload:closeAlert": _ctx.hideDownloadAlert
    }, null, 8, ["isHidden", "itemID", "onDownload:closeAlert"])
  ], 64))
}