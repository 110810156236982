<template>
  <div v-if="!isHidden" id="alertBackground">
    <footer id="alertWrapper">
      <div class="container">
        <div class="row justify-content-center">
          <div class="col-11 col-md-8 col-lg-6 col-xl-5 col-xxl-4" style="padding: 0">
            <div id="alert">
              <svg id="closeButton" @click="closeAlert">
                <use href="@/assets/icons/close.svg#shape"></use>
              </svg>

              <img src="@/assets/images/invite.gif" id="alertImage" />
              <p id="alertTitle">{{ t('getTheApp') }}</p>

              <p id="alertText">
                {{ t('getTheAppDescription') }}
              </p>

              <div id="alertButtonWrapper">
                <a
                  class="btn btn-success alertButton"
                  id="openButton"
                  @click="openApp"
                  >{{ t('open') }}
                </a>

                <a
                  class="btn alertButton"
                  id="noThanksButton"
                  v-on:click="closeAlert"
                >
                  {{ t('noThanks') }}
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </footer>
  </div>
</template>

<script lang="ts">
import * as router from '@/router'
import { defineComponent } from 'vue'
import { logGetAppAlertClick } from '@/services/EventLogger'
import { useI18n } from 'vue-i18n'

export default defineComponent({
  setup() {
    const { t } = useI18n({})

    return { t }
  },

  props: {
    isHidden: {
      type: Boolean,
      default: false
    },
    itemID: {
      type: String,
      required: false
    }
  },

  emits: [
    'download:closeAlert'
  ],

  methods: {
    closeAlert() {
      this.$emit('download:closeAlert')
    },

    openApp() {
      logGetAppAlertClick()
      this.closeAlert()
      if (this.itemID) {
        router.default.push({ name: router.routeNames.DOWNLOAD, params: { itemId: this.itemID } })
      } else {
        router.default.push({ name: router.routeNames.DOWNLOAD })
      }
    }
  }
})
</script>

<style lang="sass" scoped>
#alertBackground
  background-color: #000000BB
  position: absolute
  top: 0
  bottom: 0
  left: 0
  right: 0

#alertWrapper
  position: fixed
  left: 0
  top: 15%
  width: 100%

#alert
  position: relative
  border-radius: 12px
  padding: 0px
  width: 100%
  overflow: hidden
  background-color: $alert_background

#alertTitle
  font-size: 1.6em
  text-align: center
  font-weight: 600
  padding: 20px 20px 0px 20px
  margin-bottom: 0

#alertText
  text-align: center
  padding: 20px 20px 0px 20px
  margin-bottom: 0

#alertButtonWrapper
  padding: 20px

.alertButton
  width: 100%
  border-radius: 12px
  font-weight: 500

#openButton
  padding: 12px

#noThanksButton
  background: $alert_background
  font-size: 0.9em
  padding: 12px
  margin-top: 10px
  color: $color_on_background

#alertImage
  width: 100%
  display: block

#closeButton
  cursor: pointer
  height: 30px
  width: 30px
  position: absolute
  right: 12px
  top: 12px
  fill: $color_on_background
</style>

<i18n>
{
  "de": {
    "getTheAppDescription": "Bewerte und kommentiere Beiträge - oder lade selber welche hoch!",
    "noThanks": "Nein, danke",
    "open": "Öffnen"
  },

  "en": {
    "getTheAppDescription": "Vote and comment on posts - or upload your own!",
    "noThanks": "No, thanks",
    "open": "Open"
  }
}
</i18n>
